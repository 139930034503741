import requestService from "../requestService";
import store from "@/store";

export default {
    async updateContent(id, form) {
        const response = await requestService.post(`/admin/content/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getContentByType() {
        const response = await requestService.get(`/admin/contents`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getContentForUser(name) {
        const response = await requestService.get(`/content/${name}`, {
            headers: `Accept-Language:` + store.getters.user.locale

        })
        return response?.data
    },
    async createFaq(form) {
        const response = await requestService.post(`/admin/faq`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async updateFaq(id, form) {
        const response = await requestService.post(`/admin/faq/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getFaqByType(type) {
        const response = await requestService.get(`/admin/faqs/${type}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getFaqForUserByType(type) {
        const response = await requestService.get(`/faq/${type}`, {
            headers: `Accept-Language:` + store.getters.user.locale
        })
        return response?.data
    },
    async deleteFAQ(id) {
        const response = await requestService.delete(`/admin/faq/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
}