<template>
  <v-footer class="footer" padless color="#1b1b1b">
    <v-row no-gutters align="start" v-if="!$vuetify.breakpoint.xs">
      <v-col cols="2" class="py-0 spaceBetweenSection">
        <img src="@/assets/img/footerLogo.svg" alt="footer logo" />
        <!-- <v-row no-gutters align="center" style="margin-left: 34px">
          <a
            v-for="network in socialNetwork"
            :key="network.id"
            :href="network.href"
            class="networkIcon"
          >
            <v-icon color="white">{{ network.icon }}</v-icon>
          </a>
        </v-row> -->
      </v-col>
      <v-col cols="2" class="py-0 spaceBetweenSection">
        <p class="sectionName">{{ "address_footer_label" | localize }}</p>
        <p
          class="sectionText"
          v-if="content && content.address"
          v-html="content.address.text"
        ></p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="sectionName">{{ "contact_footer_label" | localize }}</p>
        <p class="sectionText" v-if="content && content.email">
          <a
            :href="`mailto:${content.email.text}`"
            v-html="content.email.text"
          ></a>
        </p>
        <p class="sectionText" v-if="content && content.phone_number">
          <a
            :href="`tel: ${content.phone_number.text}`"
            v-html="content.phone_number.text"
          ></a>
        </p>
      </v-col>
    </v-row>
    <v-col v-else class="center py-0 px-0">
      <img
        src="@/assets/img/footerLogo.svg"
        width="139px"
        height="30px"
        alt="footer logo"
      />
      <!-- <v-row no-gutters align="center" justify="center">
        <a
          v-for="network in socialNetwork"
          :key="network.id"
          :href="network.href"
          class="networkIcon"
        >
          <v-icon color="white" small>{{ network.icon }}</v-icon>
        </a>
      </v-row> -->
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppFooter",
  data: () => ({
    socialNetwork: [
      {
        id: 1,
        icon: "mdi-facebook",
        href: "https://www.facebook.com/",
      },
      {
        id: 2,
        icon: "mdi-instagram",
        href: "https://www.instagram.com/",
      },
      {
        id: 3,
        icon: "mdi-twitter",
        href: "https://twitter.com/?lang=uk",
      },
      {
        id: 4,
        icon: "mdi-whatsapp",
        href: "https://www.whatsapp.com/?lang=ua",
      },
    ],
  }),
  computed: {
    ...mapGetters(["content"]),
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .footer {
    width: 100%;
    height: 125px;
    padding: 30px;
  }
  .networkIcon {
    margin-right: 30px;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 601px) {
  .footer {
    width: 100%;
    height: 301px;
    padding: 100px 0px 100px 40px;
  }
  .networkIcon {
    margin-right: 30px;
    margin-top: 30px;
  }
}
a {
  text-decoration: none;
  color: #c0c0c0 !important;
}
.networkIcon:nth-child(4n) {
  margin-right: 0px;
}
.sectionName {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 20px;
}
.sectionText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #c0c0c0;
}
.spaceBetweenSection {
  margin-right: 100px;
}
</style>