export default {
  state: {
    user: {
      locale: 'ua'
    },
    loggedUser: null,
    content: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setLoggedUser(state, loggedUser) {
      state.loggedUser = loggedUser
    },
    setContent(state, content) {
      state.content = content
    },
    clearUser(state) {
      state.user = {}
    },
    clearLoggedUser(state) {
      state.loggedUser = null
    },
    clearContent(state){
      state.content = {}
    }
  },
  actions: {
    async updateInfo({ commit, getters }, toUpdate) {
      try {
        const updateData = { ...getters.user, ...toUpdate }
        commit('setUser', updateData)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async updateInfoLogged({ commit, getters }, toUpdate) {
      try {
        const updateData = { ...getters.loggedUser, ...toUpdate }
        commit('setLoggedUser', updateData)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async updateContent({ commit, getters }, toUpdate) {
      try {
        const updateData = { ...getters.content, ...toUpdate }
        commit('setContent', updateData)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },
  getters: {
    user: s => s.user,
    loggedUser: s => s.loggedUser,
    content: s => s.content
  }
}
