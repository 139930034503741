<template>
  <v-navigation-drawer
    v-model="showDrawer"
    absolute
    clipped
    app
    width="295px"
    style="z-index: 100"
  >
    <v-col class="center">
      <v-row no-gutters justify="center">
        <img
          src="@/assets/img/logo.svg"
          width="58px"
          height="40px"
          alt="logo"
        />
      </v-row>
      <v-row no-gutters justify="center">
        <div
          :class="item.path == $route.path ? 'activeMenuItem' : 'menuItem'"
          v-for="item in menuItems"
          :key="item.id"
        >
          <!-- Router link to go to the link page -->
          <router-link
            v-if="loggedUser ? item : item.require_login == false"
            :to="item.path"
            style="text-decoration: none; color: black"
          >
            <!-- Page name -->
            <span
              class="name"
              :class="item.path == $route.path ? 'activeMenuName' : ''"
            >
              {{ item.title | localize }}
            </span>
          </router-link>
        </div>
        <div
          style="border-bottom: 1px solid rgba(248, 197, 0, 0.6); width: 50px"
        ></div>
      </v-row>
      <v-col cols="12" class="center py-0" style="margin-top: 30px" :key="key">
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-model="languageMenu"
          max-width="167px"
          nudge-right="40px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- Active user language -->
            <v-row
              no-gutters
              align="center"
              justify="center"
              v-bind="attrs"
              v-on="on"
            >
              <img
                :src="
                  require(`@/assets/img/countryFlags/${activeLanguage.country_flag}`)
                "
                style="margin-right: 10px"
              />
              <span>{{ activeLanguage.language }}</span>
              <v-icon v-if="languageMenu" color="black">mdi-chevron-up</v-icon>
              <v-icon v-else color="black">mdi-chevron-down</v-icon>
            </v-row>
          </template>
          <!-- Languages list menu -->
          <v-card width="167px">
            <v-col class="px-0 py-0">
              <!-- Languages list -->
              <v-row
                no-gutters
                align="center"
                v-for="language in languagesList"
                :key="language.id"
                :class="
                  language.language_code !== user.locale.toUpperCase()
                    ? 'language'
                    : ''
                "
                @click="changeUserLanguage(language.language_code)"
              >
                <img
                  :src="
                    require(`@/assets/img/countryFlags/${language.country_flag}`)
                  "
                  style="
                    margin-right: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #1b1b1b;
                  "
                  v-if="language.language_code !== user.locale.toUpperCase()"
                />
                <span
                  v-if="language.language_code !== user.locale.toUpperCase()"
                  >{{ language.language }} ({{ language.language_code }})</span
                >
              </v-row>
            </v-col>
          </v-card>
        </v-menu>
      </v-col>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    showDrawer: true,
    menuItems: [
      {
        id: 1,
        title: "header_item_main",
        path: "/",
        require_login: false,
      },
      {
        id: 2,
        title: "header_item_delivery",
        path: "/delivery",
        require_login: false,
      },
      {
        id: 3,
        title: "header_item_about_us",
        path: "/about_us",
        require_login: false,
      },
      {
        id: 4,
        title: "header_item_contact",
        path: "/contact",
        require_login: false,
      },
      {
        id: 5,
        title: "header_item_admin",
        path: "/admin/0",
        require_login: true,
      },
    ],
    languagesList: [
      {
        id: 1,
        language: "Українська",
        language_code: "UA",
        country_flag: "UkraineFlag.svg",
      },
      {
        id: 2,
        language: "English",
        language_code: "EN",
        country_flag: "UnitedStateFlag.svg",
      },
      {
        id: 3,
        language: "Deutsch",
        language_code: "DE",
        country_flag: "DeutschFlag.svg",
      },
      {
        id: 4,
        language: "Polish",
        language_code: "PL",
        country_flag: "PolandFlag.svg",
      },
    ],
    languageMenu: false,
    activeLanguage: {},
    key: 0,
  }),
  mounted() {
    this.setActiveLanguage();
    setTimeout(() => {
      this.key++;
    }, 10);
  },
  methods: {
    //Actions in vuex for change user
    ...mapActions(["updateInfo"]),
    //Set now user language
    setActiveLanguage() {
      // eslint-disable-next-line
      //Filter the available languages ​​to get the user's language
      this.activeLanguage = this.languagesList.filter(
        (language) => language.language_code == this.user.locale.toUpperCase()
      );
      //Get first element in array
      this.activeLanguage = this.activeLanguage[0];
      //Close language menu
      this.languageMenu = false;
    },
    //Change user language
    changeUserLanguage(language_code) {
      //Update user info in vuex
      this.updateInfo({
        locale: language_code.toLowerCase(),
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    showDrawer: {
      deep: true,
      handler() {
        this.$emit("close");
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.setActiveLanguage();
      },
    },
  },
};
</script>

<style scoped>
.activeMenuItem {
  background: rgba(248, 197, 0, 0.7);
  border-radius: 3px;
  width: 150px;
  height: 25px;
  transform: skewX(20deg);
  margin-top: 20px;
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.activeMenuName {
  display: inline-block;
  transform: skewX(-20deg);
}
.menuItem {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.language {
  cursor: pointer;
  padding: 7px 10px 7px 10px;
}
.language:hover {
  cursor: pointer;
  background-color: silver;
  padding: 7px 10px 7px 10px;
}
</style>