import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/forUser/mainPage.vue'),
    },
    {
        path: '/delivery',
        name: 'delivery',
        component: () => import('@/views/forUser/deliveryPage.vue'),
    },
    {
        path: '/about_us',
        name: 'about_us',
        component: () => import('@/views/forUser/aboutUsPage.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/forUser/contactPage.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/forAdmin/loginPage.vue'),
    },
    {
        path: '/admin',
        redirect: `/admin/0`
    },
    {
        path: '/admin/:section_id',
        name: 'admin',
        component: () => import('@/views/forAdmin/adminPage.vue'),
        meta: { requiresLogin: true }
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        component: () => import('@/views/forAdmin/resetPassword.vue')
    },
    {
        path: '/reset_password/:code',
        name: 'reset_password_code',
        component: () => import('@/views/forAdmin/inputNewPassword.vue'),
    },
    { path: '*', redirect: `/404` },
    {
        path: `/404`,
        name: 'not_found_page',
        component: () => import("@/components/404.vue")
    }
]
const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin) && store.getters.loggedUser == null) {
        next({ name: 'login' })
    } else if (to.matched.some(record => record.meta.requiresLogin) && store.getters.loggedUser) {
        next()
    }
    else {
        next();
    }
})

export default router;