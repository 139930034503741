import store from '../store'
import en from '@/locales/en.json'
import ua from '@/locales/ua.json'
import de from '@/locales/de.json'
import pl from '@/locales/pl.json'
import cz from '@/locales/cz.json'

const locales = {
    'en': en,
    'ua': ua,
    'de': de,
    'pl': pl,
    'cz': cz,
}

export default function localizeFilter(key) {
    let locale = "ua";
    locale = store.getters.user.locale || "ua"
    return locales[locale][key]
}