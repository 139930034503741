<template>
  <v-app-bar
    :height="$vuetify.breakpoint.xs ? '70px' : '110px'"
    color="#FFFFFF"
  >
    <v-row no-gutters align="center" v-if="!$vuetify.breakpoint.xs">
      <!-- Menu navigation -->
      <v-col cols="5" class="d-flex" style="padding-left: 10px">
        <div
          :class="
            item.path == `${$route.path}?lang=${$store.getters.user.locale}`
              ? 'activeMenuItem'
              : 'menuItem'
          "
          v-for="item in menuItems"
          :key="item.id"
        >
          <!-- Router link to go to the link page -->
          <router-link
            v-if="loggedUser ? item : item.require_login == false"
            :to="item.path"
            style="text-decoration: none; color: black"
          >
            <!-- Page name -->
            <span
              class="name"
              :class="
                item.path == `${$route.path}?lang=${$store.getters.user.locale}`
                  ? 'activeMenuName'
                  : ''
              "
            >
              {{ item.title | localize }}
            </span>
          </router-link>
        </div>
      </v-col>
      <!-- Company logo and name -->
      <v-col cols="6">
        <v-row
          no-gutters
          align="center"
          class="pointer"
          @click="$router.push('/')"
        >
          <img src="@/assets/img/logo.svg" style="margin-right: 10px" />
          <v-col class="px-0">
            <span
              style="
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #1b1b1b;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none;
              "
            >
              GPE
            </span>
            <br />
            <span
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #1b1b1b;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none;
              "
              >GLOBAL PRO EXPORT</span
            >
          </v-col>
        </v-row>
      </v-col>
      <!-- Language dropdown -->
      <v-col cols="1" :key="key">
        <v-menu offset-y :close-on-content-click="false" v-model="languageMenu">
          <template v-slot:activator="{ on, attrs }">
            <!-- Active user language -->
            <v-row
              no-gutters
              align="center"
              v-bind="attrs"
              v-on="on"
              v-if="activeLanguage !== ''"
            >
              <img
                width="22px"
                height="15px"
                :src="
                  require(`@/assets/img/countryFlags/${activeLanguage.country_flag}`)
                "
                style="margin-right: 10px"
              />
              <span>{{ activeLanguage.language_code }}</span>
              <v-icon v-if="languageMenu" color="black">mdi-chevron-up</v-icon>
              <v-icon v-else color="black">mdi-chevron-down</v-icon>
            </v-row>
          </template>
          <!-- Languages list menu -->
          <v-card width="max-content" v-if="languagesList.length > 0">
            <v-col class="px-0 py-0">
              <!-- Languages list -->
              <v-row
                no-gutters
                align="center"
                v-for="language in languagesList"
                :key="language.id"
                :class="
                  language.language_code !== user.locale.toUpperCase()
                    ? 'language'
                    : ''
                "
                @click="changeUserLanguage(language.language_code)"
              >
                <img
                  width="22px"
                  height="15px"
                  :src="
                    require(`@/assets/img/countryFlags/${language.country_flag}`)
                  "
                  style="
                    margin-right: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #1b1b1b;
                  "
                  v-if="language.language_code !== user.locale.toUpperCase()"
                />
                <span
                  v-if="language.language_code !== user.locale.toUpperCase()"
                  >{{ language.language }} ({{ language.language_code }})</span
                >
              </v-row>
            </v-col>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-else no-gutters justify="center">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="5" style="text-align: right" class="py-0">
          <img
            src="@/assets/img/logo.svg"
            width="50px"
            height="50px"
            style="margin-right: 10px; margin-top: 10px"
            @click="$router.push('/')"
          />
        </v-col>
        <v-col class="px-0" cols="5" @click="$router.push('/')">
          <span
            style="
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              color: #1b1b1b;
              -webkit-user-select: none; /* Safari */
              -ms-user-select: none; /* IE 10 and IE 11 */
              user-select: none;
            "
          >
            GPE
          </span>
          <br />
          <span
            style="
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: #1b1b1b;
              -webkit-user-select: none; /* Safari */
              -ms-user-select: none; /* IE 10 and IE 11 */
              user-select: none;
            "
            >GLOBAL PRO EXPORT</span
          >
        </v-col>
        <v-col cols="2" style="text-align: right">
          <v-icon color="#1B1B1B" @click="$emit('showNavigationDrawer')"
            >mdi-menu</v-icon
          >
        </v-col>
      </v-row>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AppHeader",
  data: () => ({
    menuItems: [],
    languagesList: [
      {
        id: 1,
        language: "Українська",
        language_code: "UA",
        country_flag: "UkraineFlag.svg",
      },
      {
        id: 2,
        language: "English",
        language_code: "EN",
        country_flag: "UnitedStateFlag.svg",
      },
      {
        id: 3,
        language: "Deutsch",
        language_code: "DE",
        country_flag: "DeutschFlag.svg",
      },
      {
        id: 4,
        language: "Polish",
        language_code: "PL",
        country_flag: "PolandFlag.svg",
      },
      {
        id: 5,
        language: "Czech",
        language_code: "CZ",
        country_flag: "CzechFlag.svg",
      },
    ],
    languageMenu: false,
    activeLanguage: "",
    key: 0,
  }),
  mounted() {
    this.setMenuItems();
    setTimeout(() => {
      this.setActiveLanguage();
      this.key++;
    }, 100);
  },
  methods: {
    //Actions in vuex for change user
    ...mapActions(["updateInfo"]),
    //Set now user language
    setActiveLanguage() {
      // eslint-disable-next-line
      //Filter the available languages ​​to get the user's language
      this.activeLanguage = this.languagesList.filter(
        (language) => language.language_code == this.user.locale.toUpperCase()
      );
      //Get first element in array
      this.activeLanguage = this.activeLanguage[0];
      //Close language menu
      this.languageMenu = false;
    },
    //set Menu Items For User Lang
    setMenuItems() {
      this.menuItems = [
        {
          id: 1,
          title: "header_item_main",
          path: `/?lang=${this.$store.getters.user.locale}`,
          require_login: false,
        },
        {
          id: 2,
          title: "header_item_delivery",
          path: `/delivery?lang=${this.$store.getters.user.locale}`,
          require_login: false,
        },
        {
          id: 3,
          title: "header_item_about_us",
          path: `/about_us?lang=${this.$store.getters.user.locale}`,
          require_login: false,
        },
        {
          id: 4,
          title: "header_item_contact",
          path: `/contact?lang=${this.$store.getters.user.locale}`,
          require_login: false,
        },
        {
          id: 5,
          title: "header_item_admin",
          path: "/admin/0",
          require_login: true,
        },
      ];
    },
    //Change user language
    changeUserLanguage(language_code) {
      //Update user info in vuex
      this.updateInfo({
        locale: language_code.toLowerCase(),
      });
    },
  },
  computed: {
    //Get user form vuex
    ...mapGetters(["user"]),
    //GET LOGGED USER FROM VUEX
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    //Detect change user locale(language)
    "user.locale": {
      deep: true,
      handler() {
        this.setMenuItems();
        this.setActiveLanguage();
      },
    },
  },
};
</script>

<style scoped>
.activeMenuItem {
  background: rgba(248, 197, 0, 0.7);
  border-radius: 3px;
  text-align: center;
  margin-right: 13px;
  display: inline-block;
  align-self: center;
  align-items: center;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
  transform: skewX(20deg);
}

@keyframes changeItem {
  from {
    width: 0px;
  }

  to {
    width: 100px;
  }
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.activeMenuName {
  display: inline-block;
  transform: skewX(-20deg);
}
.menuItem {
  text-align: center;
  margin-right: 13px;
}
.language {
  cursor: pointer;
  padding: 7px 10px 7px 10px;
}
.language:hover {
  cursor: pointer;
  background-color: silver;
  padding: 7px 10px 7px 10px;
}
</style>