import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import store from './store';
import localizeFilter from './filters/localize.filter'
import './assets/layouts/index.scss'
import Vue2Editor from "vue2-editor";

Vue.config.productionTip = false

Vue.filter('localize', localizeFilter)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDjv7izdGTG6V_5XF7VwqJOzbjEYYiJjKc',
    libraries: 'places',
    region: 'UA',
    language: "UA",
  }
})
Vue.use(VueMask);
Vue.filter('localize', localizeFilter)
Vue.use(Vuelidate)
Vue.use(Vue2Editor);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')