<template>
  <div id="app">
    <v-app>
      <Header @showNavigationDrawer="showNavigationDrawer = true" />
      <mobile-navigation-drawer
        v-if="showNavigationDrawer"
        @close="showNavigationDrawer = false"
      />
      <router-view />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import MobileNavigationDrawer from "./components/mobileNavigationDrawer.vue";
import { mapGetters, mapActions } from "vuex";
import contentService from "./requests/admin/contentService";
export default {
  name: "App",
  components: { Header, Footer, MobileNavigationDrawer },
  data: () => ({
    showNavigationDrawer: false,
  }),
  mounted() {
    document.title = this.$options.filters.localize("site_title");
    this.getContentForUser();
  },
  methods: {
    ...mapActions(["updateContent"]),
    async getContentForUser() {
      this.contactContent = [];
      let address = {};
      let phone_number = {};
      let email = {};
      let working_days = {};
      await contentService.getContentForUser("contact_address").then((res) => {
        address = {
          text: res.data.content,
          img: "location.png",
        };
      });
      await contentService
        .getContentForUser("contact_phone_number")
        .then((res) => {
          phone_number = {
            text: res.data.content,
            img: "telephone.png",
          };
        });
      await contentService.getContentForUser("contact_email").then((res) => {
        email = {
          text: res.data.content,
          img: "email.png",
        };
      });
      await contentService
        .getContentForUser("contact_working_days")
        .then((res) => {
          working_days = {
            text: res.data.content,
            img: "clock.png",
          };
        });
      this.updateContent({
        address: address,
        phone_number: phone_number,
        email: email,
        working_days: working_days,
      });
      this.showLoader = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    //   "user.locale": {
    //     deep: true,
    //     handler() {
    //       document.title = this.$options.filters.localize("site_title");
    //       this.getContentForUser();
    //     },
    //   },
    "$route.query.lang": {
      handler() {
        document.title = this.$options.filters.localize("site_title");
        this.getContentForUser();
      },
    },
  },
};
</script>

<style>
#app {
  font-family: "Raleway";
  font-style: normal;
  overflow-x: hidden;
}
</style>
